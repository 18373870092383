import React from "react"
import Layout from "../components/layout"
import VideoPicker from "../components/video/videoPickerComponent"

const HomePage = ({ vid }) => {
  return (
    <Layout>
      <VideoPicker vid={vid} />
    </Layout>
  )
}

export default HomePage
